<mat-toolbar class="toolbar">
  <button mat-icon-button (click)="authService.isExpanded = !authService.isExpanded">
    <mat-icon>menu</mat-icon>
  </button>
  {{utilService.customerName}}
  <div class="company-logo">
    <button mat-button routerLink="/dashboard">
      <img class="company-image" src="assets/logo/AFS_PrimaryLogo_1C_White.png" />
    </button>
  </div>
  <span class="example-spacer"></span>
  <button matTooltip="Switch Company" mat-icon-button *ngIf="authService.isAfsUser()" (click)="switchCompany()" >
    <mat-icon style="font-size: 29px;" class="material-icons-outlined">swap_horiz</mat-icon>
  </button> &nbsp;
  <button matTooltip="Logout" mat-icon-button (click)="logout()">
    <mat-icon class="material-icons-outlined">logout</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false">
  <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="55"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
    [opened]="true">
    <div class="side-menu-height">
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN')  && authService.isNotAfsCustomer()">
        <a mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">dashboard</mat-icon>
          <span [translate]="'Dashboard'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Dashboard</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN') && authService.isNotAfsCustomer()">
        <a mat-list-item [routerLink]="['/reports']" matTooltip="Reports" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">auto_awesome_mosaic</mat-icon>
          <span [translate]="'Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Reports</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('CUSTOMER')">
        <a mat-list-item [routerLink]="['/customers']" matTooltip="Customers" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon>corporate_fare</mat-icon>
          <span [translate]="'Customers'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Customers</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('USER')">
        <a mat-list-item [routerLink]="['/users']" matTooltip="Users" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">people</mat-icon>
          <span [translate]="'Users'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Users</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SERVICE_REQUEST')">
        <a mat-list-item [routerLink]="['/service-requests']" matTooltipPosition="right" matTooltip="Service Requests"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">content_paste</mat-icon>

          <span [translate]="'Service Requests'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Service
            Requests</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
        <a mat-list-item [routerLink]="['/timed-services']" matTooltipPosition="right" matTooltip="Timed Services"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">timer</mat-icon>
          <span [translate]="'Timed Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Timed
            Services</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
        <a mat-list-item [routerLink]="['/timed-service-schedules']" matTooltipPosition="right"
          matTooltip="Timed Service Schedules" routerLinkActive="active-list-item">
          <mat-icon class="material-icons">pending_actions</mat-icon>
          <span [translate]="'Timed Service Schedules'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Timed
            Service Schedules</span>
        </a>
      </mat-nav-list>
      <mat-nav-list
        *ngIf="(authService.checkRoleBasedModuleAccess('MESSAGE') || authService.checkRoleBasedModuleAccess('SERVICE_REQUEST'))">
        <a mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">feedback</mat-icon>
          <span [translate]="'Notifications'" *ngIf="authService.isExpanded"
            class="menu-icon-spacing">Notifications</span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
        <a mat-list-item [routerLink]="['/audits']" matTooltip="Services" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon>checklist_rtl</mat-icon>
          <span [translate]="'Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Services </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
        <a mat-list-item [routerLink]="['/audit-schedule']" matTooltip="Scheduled Services" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon>schedule</mat-icon>
          <span [translate]="'Scheduled Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Scheduled
            Services
          </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('LOPA')">
        <a mat-list-item [routerLink]="['/lopa']" matTooltip="LOPA Management" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">flight</mat-icon>
          <span [translate]="'Lopa'" *ngIf="authService.isExpanded" class="menu-icon-spacing">LOPA Management
          </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
        <a mat-list-item [routerLink]="['/locations']" matTooltip="Locations" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">my_location</mat-icon>
          <span [translate]="'Locations'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Locations
          </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
        <a mat-list-item [routerLink]="['/parts']" matTooltip="Parts" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">chair_alt</mat-icon>
          <span [translate]="'Parts'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Parts
          </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BUDGET')">
        <a mat-list-item [routerLink]="['/budget']" matTooltip="Budget" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">card_travel</mat-icon>
          <span [translate]="'Budget'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Budget
          </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('INVOICE')">
        <a mat-list-item [routerLink]="['/invoice']" matTooltip="Invoice" matTooltipPosition="right"
          routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">description</mat-icon>
          <span [translate]="'Invoice'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Invoice
          </span>
        </a>
      </mat-nav-list>
      <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('PART_ORDER') || authService.checkRoleBasedModuleAccess('PART_ORDER_READ_ONLY')">
        <a mat-list-item [routerLink]="['/part-orders']" matTooltip="Part Orders" matTooltipPosition="right"
            routerLinkActive="active-list-item">
            <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
            <span [translate]="'Part Orders'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Part Orders
            </span>
        </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT') || authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT_READ_ONLY')">
        <a mat-list-item [routerLink]="['/billable-reports']" matTooltip="Billable Reports" matTooltipPosition="right"
            routerLinkActive="active-list-item">
            <mat-icon class="material-symbols-outlined material-icons-outlined">receipt_long</mat-icon>
            <span [translate]="'Billable Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Billable Reports
            </span>
        </a>
        </mat-nav-list>
    </div>
    <div [ngClass]='appropriateClass'>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="right"
          routerLinkActive="active-list-item" *ngIf="authService.checkRoleBasedModuleAccess('SETTINGS')">
          <mat-icon class="material-icons-outlined">settings</mat-icon>
          <span [translate]="'Settings'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Settings
          </span>
        </a>

        <a (click)="authService.isExpanded = !authService.isExpanded" mat-list-item>
          <mat-icon *ngIf="authService.isExpanded">keyboard_double_arrow_left</mat-icon>
          <mat-icon *ngIf="!authService.isExpanded">keyboard_double_arrow_right</mat-icon>
          <span [translate]="'Collapse sidebar'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Collapse
            sidebar</span>
          <span slot="right" style="color: grey;font-size: small; margin: 0 0 0 auto;" *ngIf="authService.isExpanded"
            class="menu-icon-spacing">
            {{version }}</span>
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="toolbar-height-50">
      <span>Locations</span>
      <span class="toolbar-spacer"></span>
      <button mat-icon-button (click)="refreshData()" matTooltip="Refresh Locations">
        <mat-icon class="primary-color">refresh</mat-icon>
      </button>
    </mat-toolbar>

    <div class="container">
      <div fxLayout="row" class="div-row">

        <div fxFlex="35" class="location-div">
          <mat-toolbar class="toolbar-fixed" style="height:50px !important;" *ngIf="(!dataSource.data || dataSource.data.length == 0) && !isShowProgressBar">
            <button style="padding-left:0px" mat-button color="primary" (click)="addLocation()" onclick="this.blur()">
              <mat-icon>add</mat-icon>
              Add New
            </button>
            <span class="example-spacer"></span>
          </mat-toolbar>

          <mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate">
          </mat-progress-bar>
          <form [formGroup]="locationFormGroup">
            <mat-radio-group formControlName="locCtrl">
              <mat-tree style="margin-top:20px" [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <button mat-icon-button disabled></button>
                  <mat-radio-button (change)="getLocationDetails(node.id)" [value]="node.id">
                    {{node.name}}</mat-radio-button>
                  <button mat-icon-button (click)="addSublocation(node)" matTooltip="Add Sub-Location"
                    matTooltipPosition="right" *ngIf="!isAirlineCusromer">
                    <mat-icon class="material-icons-outlined">add_location_alt</mat-icon>
                  </button>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                  <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  <mat-radio-button (change)="getLocationDetails(node.id)" [value]="node.id">
                    {{node.name}}</mat-radio-button>
                  <button mat-icon-button (click)="addSublocation(node)" *ngIf="!isAirlineCusromer">
                    <mat-icon class="material-icons-outlined" matTooltip="Add Sub-Location" matTooltipPosition="right">
                      add_location_alt</mat-icon>
                  </button>
                </mat-tree-node>
              </mat-tree>
            </mat-radio-group>

          </form>

          <div class="centered" *ngIf="(!dataSource.data || dataSource.data.length == 0) && !isShowProgressBar">
            <button mat-button (click)="addLocation()" class="add-location-button">
              <mat-icon>add</mat-icon>
              Add your base location
            </button>
          </div>
        </div>

        <div fxFlex="65" class="category-div">
          <mat-tab-group mat-stretch-tabs mat-align-tabs="start" [selectedIndex]="selectedIndex"
            (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Location">
              <mat-progress-bar *ngIf="isShowlocationDetailsProgressBar" mode="indeterminate">
              </mat-progress-bar>
              <div *ngIf="locationFormGroup.valid">
                <div style="text-align: right;padding-top: 10px;">
                  <button mat-icon-button (click)="editLocationDetails()" matTooltip="Edit"
                    *ngIf="this.locationDetailsFormGroup.disabled" [disabled]="!isAirlineCusromer">
                    <mat-icon
                      [ngClass]="!isAirlineCusromer? 'material-icons-outlined': 'primary-color material-icons-outlined'">
                      edit
                    </mat-icon>
                  </button>
                  <button mat-icon-button (click)="cancelEditLocationDetails()" matTooltip="Cancel"
                    *ngIf="!this.locationDetailsFormGroup.disabled">
                    <mat-icon class="primary-color material-icons-outlined">
                      close
                    </mat-icon>
                  </button>
                </div>

                <form style="margin:0px 0px 10px 0px;padding: 10px;font-size: 14px !important;"
                  [formGroup]="locationDetailsFormGroup">
                  <mat-form-field appearance="legacy">
                    <mat-label>Location Id</mat-label>
                    <input autocomplete="off" matInput type="text" required formControlName="locationId">
                  </mat-form-field>

                  <mat-form-field appearance="legacy">
                    <mat-label>Name</mat-label>
                    <input autocomplete="off" matInput type="text" required formControlName="name">
                  </mat-form-field>

                  <div style="display:flex">
                    <mat-form-field appearance="legacy">
                      <mat-label>Category</mat-label>
                      <mat-select formControlName="category" required disableOptionCentering>
                        <mat-option *ngFor="let cat of locationCategories" [value]="cat.CATEGORY_ID">
                          {{cat.NAME}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <button mat-icon-button matTooltip="Add Category" (click)="addCategory()"
                      *ngIf="!this.locationDetailsFormGroup.disabled">
                      <mat-icon style="color:#00629b;font-size: 30px;" class="material-icons-outlined">add
                      </mat-icon>
                    </button>

                    <button mat-icon-button matTooltip="Edit Category" (click)="editCategory()"
                      *ngIf="!this.locationDetailsFormGroup.disabled"
                      [disabled]="!locationDetailsFormGroup.controls.category.valid">
                      <mat-icon style="color:#00629b" class="material-icons-outlined">edit
                      </mat-icon>
                    </button>
                  </div>

                  <mat-form-field appearance="legacy">
                    <mat-label>Description</mat-label>
                    <input autocomplete="off" matInput type="text" formControlName="description">
                  </mat-form-field>

                  <mat-form-field appearance="legacy">
                    <mat-label>Latitude</mat-label>
                    <input autocomplete="off" matInput type="number" formControlName="latitude">
                  </mat-form-field>

                  <mat-form-field appearance="legacy">
                    <mat-label>Longitude</mat-label>
                    <input autocomplete="off" matInput type="number" formControlName="longitude">
                  </mat-form-field>
                  <div style="text-align:center;padding-top:10px">
                    <button mat-raised-button *ngIf="!this.locationDetailsFormGroup.disabled"
                      (click)="updateLocationDetails()"
                      [ngClass]="locationDetailsFormGroup.valid ? 'primary-button' : ''"
                      [disabled]="!locationDetailsFormGroup.valid">
                      <span> Update </span>
                    </button>
                  </div>

                  <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
                    <p>{{errorMsg}}</p>
                  </div>
                </form>
              </div>
              <div class="centered" *ngIf="!locationFormGroup.valid">
                <p class="add-location-p-tag">
                  Select location to view details
                </p>
              </div>
            </mat-tab>

            <mat-tab label="Equipment">
              <div class="container mat-elevation-z4" *ngIf="locationFormGroup.valid">
                <mat-progress-bar *ngIf="isShowlocationDetailsProgressBar" class="primary-color" mode="indeterminate">
                </mat-progress-bar>
                <mat-toolbar class="container-toolbar">
                  <mat-toolbar-row class="container-toolbar-row">
                    <button mat-icon-button (click)="addEquipment()" matTooltip="Add Equipment"
                      *ngIf="selectedIndex == 1 && locationEquipments.length != 0">
                      <mat-icon style="font-size: 29px;"
                        [ngClass]="!isAirlineCusromer ? 'material-icons-outlined': 'primary-color material-icons-outlined'">
                        add</mat-icon>
                    </button>

                    <button [matMenuTriggerFor]="menu" *ngIf="selectedIndex == 1 " mat-icon-button
                      matTooltip="Filter Equipment">
                      <mat-icon class="material-icons-outlined primary-color">filter_alt
                      </mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item style="margin-right: 50px;" (click)="filterEquipments('all')">
                        <mat-icon class="primary-color material-icons-outlined">batch_prediction
                        </mat-icon>
                        <span>All</span>
                      </button>
                      <button mat-menu-item (click)="filterEquipments('active')">
                        <mat-icon class="warning-color material-icons-outlined">lightbulb
                        </mat-icon>
                        <span>Active</span>
                      </button>
                      <button mat-menu-item (click)="filterEquipments('inactive')">
                        <mat-icon class="grey-color material-icons-outlined">lightbulb
                        </mat-icon>
                        <span>Inactive</span>
                      </button>
                    </mat-menu>

                    <span class="example-spacer"></span>

                    <mat-form-field appearance="outline" style="font-size: 10px;margin: 5px 10px 0px 10px;width:200px;">
                      <mat-label>Select Category</mat-label>
                      <mat-select disableOptionCentering [(ngModel)]="selectedCategory"
                        (selectionChange)="categoryChange($event)">
                        <mat-option *ngFor="let option of equipmentCategories" [value]="option.CATEGORY_ID">
                          {{option.NAME}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-paginator #paginator [pageSize]="paginatorLength" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                      (page)="handlePaginator($event);" [length]="equipmentsLength">
                    </mat-paginator>
                  </mat-toolbar-row>
                </mat-toolbar>

                <div class="table-container" *ngIf="locationEquipments.length > 0">
                  <table mat-table (matSortChange)="sortfields($event)" [dataSource]="equipmentDataSource" matSort
                    class="example-table">

                    <!-- ID Column -->
                    <ng-container matColumnDef="EQUIPMENT_ID">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                      <td mat-cell *matCellDef="let row"> {{row.EQUIPMENT_ID}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="NAME">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td mat-cell *matCellDef="let row"> {{row.NAME}} </td>
                    </ng-container>

                    <!-- City Column -->
                    <ng-container matColumnDef="BOM_ID">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> BOM Id </th>
                      <td mat-cell *matCellDef="let row"> {{row.BOM_ID}} </td>
                    </ng-container>

                    <!-- State Column -->
                    <ng-container matColumnDef="TAG">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag </th>
                      <td mat-cell *matCellDef="let row"> {{row.TAG}} </td>
                    </ng-container>

                    <!-- Country Column -->
                    <ng-container matColumnDef="LOCATION">
                      <th mat-header-cell *matHeaderCellDef> Location </th>
                      <td mat-cell *matCellDef="let row">
                        <span *ngFor="let loc of row.LOCATION; let last = last;">
                          {{loc.NAME}}<span *ngIf="!last">,</span>
                        </span>
                        <!-- {{row.LOCATION?.Terminal?.NAME}}<span *ngIf="row.LOCATION?.Terminal?.NAME">,</span>
                        {{row.LOCATION?.Gate?.NAME}}<span *ngIf="row.LOCATION?.Gate?.NAME">,</span>
                        {{row.LOCATION?.Airport?.NAME}}<span *ngIf="row.LOCATION?.Airport?.NAME">,</span>
                        {{row.LOCATION?.City?.NAME}} -->
                      </td>
                    </ng-container>

                    <!-- State Column -->
                    <ng-container matColumnDef="DESCRIPTION">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                      <td mat-cell *matCellDef="let row"> {{row.DESCRIPTION}} </td>
                    </ng-container>

                    <!-- Zipcode Column -->
                    <ng-container matColumnDef="CATEGORY_NAME">
                      <th mat-header-cell *matHeaderCellDef> Category </th>
                      <td mat-cell *matCellDef="let row"> {{row.CATEGORY_NAME}} </td>
                    </ng-container>Actions

                    <!-- Actions Column -->
                    <ng-container matColumnDef="Actions">
                      <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Actions
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <div class="button-row">
                          <button mat-icon-button (click)="editEquipment(row)" matTooltip="Edit"
                            [disabled]="isAirlineCusromer">
                            <mat-icon
                              [ngClass]="(isAirlineCusromer)? 'material-icons-outlined': 'primary-color material-icons-outlined'">
                              edit
                            </mat-icon>
                          </button>
                          <button mat-icon-button (click)="activatAndDeavtivateEquipment(row, null)"
                            *ngIf="row.INACTIVE == 'Y'" matTooltip="Activate" [disabled]="isAirlineCusromer">
                            <mat-icon
                              [ngClass]="(isAirlineCusromer) ? 'material-icons-outlined': 'dark-success-color material-icons-outlined'">
                              refresh</mat-icon>
                          </button>

                          <button mat-icon-button (click)="activatAndDeavtivateEquipment(row, 'Y')"
                            *ngIf="(row.INACTIVE == null || row.INACTIVE != 'Y')" [disabled]="isAirlineCusromer"
                            matTooltip="Deactivate">
                            <mat-icon
                              [ngClass]="(isAirlineCusromer) ? 'material-icons-outlined': 'danger-color material-icons-outlined'">
                              delete_outline</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="9999" style="color:rgb(228, 0, 43)">
                        <span *ngIf="equipmentDataSource?.data?.length == 0">
                          No equipment found
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="centered" *ngIf="locationEquipments?.length == 0 && !locationFormGroup.valid">
                <p class="add-location-p-tag">
                  Select location to view equipment details
                </p>
              </div>

              <div class="centered" *ngIf="locationEquipments?.length == 0 && locationFormGroup.valid">
                <button mat-button (click)="addEquipment()" class="add-location-button">
                  <mat-icon>add</mat-icon>
                  Add Equipment
                </button>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>